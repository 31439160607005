import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-nav-card-wrapper',
  template: ` <ng-content></ng-content> `,
  styles: [],
  host: {
    class: 'p-4 block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  standalone: true,
})
export class NavCardWrapperComponent {}
