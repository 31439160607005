import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'mspot-card-content',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    class: 'block px-4',
  },
})
export class CardContentComponent {
  /**
   * If the card content is used with {@link CardHeaderComponent}, the padding will be provided by the component.
   *
   * @example
   * <mspot-card>
   *   <mspot-card-header>
   *   </mspot-card-header>
   *
   *   <mspot-card-content>
   *   </mspot-card-content>
   * </mspot-card>
   *
   * @param isUsedWithHeader
   */
  @Input()
  isUsedWithHeader = false;

  @HostBinding('class')
  get class(): string {
    return this.isUsedWithHeader ? 'pb-5 sm:px-6 sm:pb-6' : 'py-5 sm:p-6';
  }
}
